<template>
  <div class="container">

    <div v-if="isLoading" class="row">
      <div class="text-center mt-5">
        <spinner />
      </div>
    </div>

    <template v-else>
      <div class="row">
        <div class="col-md-8 mx-auto account-card">
          <h5>Account Settings</h5>
          <hr>

          <div>
            <!-- Account Information Form -->
            <div>
              <form @submit.prevent="updateAccountSettings">

                <div class="mb-3">
                  <label class="form-label" for="firstName">First Name</label>
                  <input type="text" class="form-control" id="firstName" v-model="user.first_name" min="2" required>
                </div>

                <div class="mb-3">
                  <label class="form-label" for="lastName">Last Name</label>
                  <input type="text" class="form-control" id="lastName" v-model="user.last_name" min="2" required>
                </div>

                <div class="mb-3">
                  <label class="form-label" for="email">Email</label>
                  <input type="email" class="form-control" id="email" v-model="user.email" disabled>
                </div>

                <div class="d-flex justify-content-between flex-column flex-md-row align-items-md-center">
                  <div class="mb-3">
                    <label class="form-label" for="role">Role</label>
                    <input type="text" class="form-control" id="role" v-model="user.role">
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="department">Department</label>
                    <input type="text" class="form-control" id="department" v-model="user.department">
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="function">Function</label>
                    <select v-model="user.function" class="form-select" id="function" name="userCompanyFunction"
                      required>
                      <option v-for="(item, index) in functionOptions" :key="index" :value="item">{{ item }}</option>
                    </select>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="allowTelemetry" :checked="telemetry"
                      v-model="telemetry">
                    <label for="allowTelemetry">Allow tracking to improve our product and services</label>
                  </div>
                  <p class="small text-muted">
                    Tracking changes will take effect the next time you login. Please note we also collect telemetry
                    data, however, this is not associated with your account.
                    <br>
                    If you'd like to delete tracking data associated with your account please email <a
                      href="mailto:help@regalytics.ai?subject=Please delete tracking data associated with my account">help@regalytics.ai</a>
                  </p>
                </div>

                <div class="mb-3">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="allowHighlightSearch"
                      :checked="search_highlight" v-model="search_highlight">
                    <label for="allowHighlightSearch">Allow highlight of keywords in advance search</label>
                  </div>
                </div>

                <div>
                  <button type="submit" class="btn btn-green">Update</button>
                </div>

              </form>
            </div>
            <!-- End of Account Information -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 mx-auto account-card">
          <h5>Account License</h5>
          <hr>
          <form @submit.prevent="updateLicenseCode">
            <div class="mb-3">
              <label class="form-label" for="companyName">Company</label>
              <input type="text" class="form-control" id="companyName" v-model="company" disabled>
            </div>
            <div class="mb-3">
              <label class="form-label" for="licenseName">License Name</label>
              <input type="text" class="form-control" id="licenseName" v-model="license.name" disabled>
            </div>
            <div class="mb-3">
              <label class="form-label" for="exportCap">Articles Export Cap</label>
              <input type="text" class="form-control" id="exportCap" v-model="license.daily_export_cap" disabled>
            </div>
            <div class="mb-3" v-if="getCurrentUser.license_type!='stripe'">
              <label class="form-label" for="accessCode">Access Code</label>
              <input type="text" class="form-control" id="accessCode" v-model="accessCode" minlength="6" maxlength="6"
                required>
            </div>
            <div>
              <button v-if="getCurrentUser.license_type!='stripe'" class="btn btn-green">Update License</button>
            </div>
          </form>
          <button class="btn btn-green" @click="redirectToBillingPortal">Manage Billing</button>

        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-8 mx-auto">
          <p class="text-center">
            View <router-link to="/terms-of-service">Terms of Service</router-link> , <router-link
              to="/privacy-policy">Privacy Policy</router-link> and <router-link to="/ai-disclaimer">AI
              Disclaimer</router-link> and <router-link to="/release-notes">Release Notes</router-link>
          </p>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner";
import { mapGetters } from "vuex";

export default {
  name: "Account",
  components: { Spinner },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        role: '',
        department: '',
        function: ''
      },
      license: {
        name: '',
        daily_export_cap: 0
      },
      company: '',
      accessCode: '',
      telemetry: true,
      search_highlight: false,
      isLoading: true,

      functionOptions: [
        'Compliance',
        'Executive Management',
        'Human Resources',
        'Investment Management',
        'Legal',
        'Sales / Business Development',
        'Risk',
        'Strategy',
        'Technology',
        'Other'
      ]

    }
  },

  methods: {
    accountDetails() {
        return this.$store.state.accounts.accountDetails;
      },
    redirectToBillingPortal() {
      axios.post('/accounts/api/stripe/create-billing-portal-session/', {}, { withCredentials: true })
        .then(response => {
          window.location.href = response.data.billing_portal_url;
        })
        .catch(error => {
          console.error('Error creating billing portal session:', error);
          this.$store.dispatch('notifications/pushNotification', {
            message: 'An error occurred while trying to create a billing portal session.',
            type: 'danger'
          });
        });
    },
    updateAccountSettings() {
      this.isLoading = true;
      axios.put('/accounts/user/information', {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        role: this.user.role,
        department: this.user.department,
        function: this.user.function,
        telemetry: this.telemetry,
        search_highlight: this.search_highlight
      }, { withCredentials: true })
        .then(response => {
          this.$store.dispatch('notifications/pushNotification', { message: 'Updated your account settings successfully!', type: 'green' });

          this.$tracking.trackEvent('updateAccountInformation', {
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            role: this.user.role,
            department: this.user.department,
            function: this.user.function
          });
          this.$store.dispatch('accounts/setSearchHighlight', this.search_highlight);
          console.log(this.getCurrentUser)

          this.isLoading = false;
        })
        .catch(err => {
          this.$store.dispatch('notifications/pushNotification', { message: 'There was an error updating your account settings, please try again!', type: 'danger' });
          this.isLoading = false;
        })
    },

    updateLicenseCode() {
      this.isLoading = true;
      axios.patch('/accounts/user/upgrade', {
        access_code: this.accessCode
      }, { withCredentials: true })
        .then(response => {
          this.$store.dispatch('notifications/pushNotification', { message: 'Updated your account license successfully!', type: 'green' });

          this.$tracking.trackEvent('updateAccountLicense', {
            access_code: this.accessCode
          });

          this.accessCode = '';
          this.isLoading = false;
        })
        .catch(err => {
          let errorMessage = 'There was an error updating your account license, please try again!';

          // If the backend returned a formatted response, list it
          if ('detail' in err.response.data) {
            errorMessage = err.response.data['detail'];
          }

          this.$store.dispatch('notifications/pushNotification', { message: errorMessage, type: 'danger' });
          this.isLoading = false;
        })
    }

  },

  created() {
    this.isLoading = true;
    axios.get('/accounts/user/information', { withCredentials: true })
      .then(response => {
        this.user = response.data.user;
        this.license = response.data.license;
        this.company = response.data.company;
        this.telemetry = response.data.telemetry;
        this.search_highlight = response.data.search_highlight;
        this.isLoading = false;
      });
  },
  computed: {
    ...mapGetters('accounts', ['getCurrentUser']),

  }

}
</script>

<style lang="scss" scoped>
.account-card {
  margin-top: 3rem;
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: 1.5rem 2.1rem;
}
</style>
